export type Region = 'au' | 'eu';

export type Env = 'dev' | 'tst' | 'stg' | 'prd';

let _headers: Headers | null = null;
export const getHeaders = async () => {
  if (_headers) return _headers;

  const { headers } = await fetch('/');
  _headers = headers;

  return _headers;
};

export const getRegion = async () => {
  const headers = await getHeaders();

  const region = (headers.get('region') ?? process.env.REACT_APP_REGION) as Region;

  return region;
};

export const getEnv = async () => {
  const headers = await getHeaders();

  const env = (headers.get('env') ?? process.env.REACT_APP_ENV) as Env;

  return env;
};

export const getAprilHost = async () => {
  const region = await getRegion();
  const env = await getEnv();

  if (env === 'prd') return `${region}.meetapril.io`;
  if (env === 'stg') return `sandbox.${region}.meetapril.io`;
  return `${env}.${region}.meetapril.dev`;
};

export const getLimepayHost = async () => {
  const env = await getEnv();

  if (env === 'prd') return `limepay.com.au`;
  if (env === 'stg') return `sandbox.limepay.com.au`;
  return `${env}.limep.net`;
};

export const getAuLimepayApiHost = async () => {
  return `https://api.${await getLimepayHost()}`;
};

export const getEuLimepayApiHost = async () => {
  return `https://api-lp.${await getAprilHost()}`;
};

export const getLimepayApiHost = async () => {
  const region = await getRegion();

  return region === 'eu' ? getEuLimepayApiHost() : getAuLimepayApiHost();
};

export const getAprilApiHost = async () => `https://api.${await getAprilHost()}`;

export const getAuCheckoutHost = async () => {
  const env = await getEnv();

  if (['prd', 'stg'].includes(env)) return getLimepayHost();
  return getAprilHost();
};

export const getEuCheckoutHost = async () => {
  return getAprilHost();
};

export const getCheckoutHost = async () => {
  const region = await getRegion();

  return region === 'eu' ? getEuCheckoutHost() : getAuCheckoutHost();
};

export const getIdentityHost = async () => {
  const region = await getRegion();
  const env = await getEnv();

  return region === 'au' && env === 'prd' ? getLimepayHost() : getAprilHost();
};

export const getCardinalHost = async () => {
  const env = await getEnv();

  if (env === 'prd') return 'https://centinelapi.cardinalcommerce.com';
  return 'https://centinelapistag.cardinalcommerce.com';
};
